// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "core-js/stable"
import "regenerator-runtime/runtime"

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels" //added for ActionCable chat

// Make jQuery available everywhere
//import "jquery"
//global.$ = require('jquery'), require('jquery-ui'), require('jquery-blockui')

//import 'bootstrap/dist/js/bootstrap'
//import 'bootstrap/dist/css/bootstrap'

//import './spectrum'
//window.spectrum = require('./spectrum');

import 'packs/spectrum'
window.spectrum = require('packs/spectrum');

import jquery from 'jquery';
window.$ = window.jquery = jquery;

//window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');
//need this line to open modal with js
//https://stackoverflow.com/questions/64113404/bootstrap-5-referenceerror-bootstrap-is-not-defined
import 'bootstrap'
window.bootstrap = require('bootstrap');

//This does not work... have to do it in one line below
//import "style-loader"
//import "css-loader"
//import "../stylesheets/application.css"
require("style-loader!css-loader!../stylesheets/application.css")
//require("style-loader!css-loader!../stylesheets/foundation-emails.css")

Rails.start()
Turbolinks.start()
ActiveStorage.start()
